
.cardImg {
  width: 380px;
}
section img.dk {
  display: none;
}

.sp, .ad, .ip{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.very{
  display: flex;
  padding-top: 10px;
  justify-content: center;
}

.flake{
  position: absolute;
  width: 100px;
  height: 100px;
  background-size: cover;
  top: 0;
}

.flake1{
  background-image: url('./assets/flakes/snowflake1.svg');
}

.flake2{
  background-image: url('./assets/flakes/snowflake2.svg');
}

.flake3{
  background-image: url('./assets/flakes/snowflake3.svg');
}

.flake4{
  background-image: url('./assets/flakes/snowflake3.svg');
}

@media (min-width: 1024px) {
  .cardImg {
    width: 465px;
  }
  
  .locale{
    position: relative;
  }

  .locations{
    width: 16vw;
    position: absolute;
  }
  .lekki{
    bottom: 12.58vw;
    left: 27.711vw;
  }
  .ibadan{
    left: 37.3677vw;
    bottom: 6.58vw;
  }
  .berger{
    left: 45.92vw;
  }
}
